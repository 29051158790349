<template>
    <div class="text-center box p-3" @click="showDetail"
           :style="borrowable.available ? {} : {filter: 'grayscale(100%)', 'background-color': '#f8f8f8'}"
           v-b-tooltip.hover="borrowable.available ? null : 'Emprunté actuellement'">
        <b-img-lazy :src="thumbnailSrc(borrowable, type)" :alt="borrowable.nom" fluid/>
        <p class="mt-2 mb-0">{{ borrowable.nom }}</p>
        <p class="mb-0 item-description">{{ textDescription }}</p>
    </div>
</template>

<script>
    import {thumbnailSrc}                           from '@/util/image';
    import {loadAndDisplayDetails, textDescription} from '@/util/borrowable';

    export default {
        name: "BorrowableItemBlock",
        props: {
            borrowable: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        computed: {
            textDescription() {
                return textDescription(this.borrowable);
            }
        },
        methods: {
            thumbnailSrc,
            showDetail() {
                loadAndDisplayDetails(this.borrowable, this.type)
                    .catch(() => this.$toaster.error('Impossible de charger le détail'));
            }
        }
    }
</script>

<style scoped>
    img.img-fluid {
        filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, .66));
    }

    .box {
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    }

    .box:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    }

    .item-description {
        font-size: 0.8rem;
        opacity: 0.8;
    }
</style>
